<template>
  <svg-icons />
  <router-view />
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import svgIcons from '@/components/SvgIcons/SvgIcons.vue'

import useFile from "@/hooks/useFile";
import { useFileApi } from '@/api/file';
import { Local } from '@/utils/storage'

const { getFolders } = useFile();
const { getPreviewHost } = useFileApi()

// 进入页面重新获取Folder
onBeforeMount(async () => {
  getPreviewHost().then((res: any) => {
    Local.set('fastDfsFileBaseUrl', res.fastDfsFileBaseUrl)
    Local.set('fastDfsImageBaseUrl', res.fastDfsImageBaseUrl)
    Local.set('onlinePreviewBaseUrl', res.onlinePreviewBaseUrl)
    Local.set('shareLinkUrl', res.shareLinkUrl)
    // Local.set('kkFileView', res.kkFileView)
    // Local.set('insideIp', res.insideIp)
    // Local.set('outsideIp', res.outsideIp)
  })
  if (Local.get('token')) {
    await getFolders()
  }
})
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html body #app {
  width: 100%;
  height: 100%;
}
</style>
