import { defineStore } from 'pinia'
import { fileInfosState } from './types';

const useFileStore = defineStore('file', {
  state: (): fileInfosState => ({
    folderId: '',
    pidRoot: '',
    pubFolderId: '',  // 公共资源
    pubPidRoot: '',  // 公共资源
    checkInfo: {
      checkIds: [],
      checkAll: false,
      isIndeterminate: false
    },
    userList: []
  }),
  persist: {
    key: 'file',
    storage: window.localStorage,
  },
})

export default useFileStore;