import { defineStore } from 'pinia'
import { UserInfosStates } from './types';
import { Session, Local } from '@/utils/storage';

const useUserStore = defineStore('user', {
  state: (): UserInfosStates => ({
    userInfos: {
      userName: '',
      avatar: '',
      time: 0,
      roles: [],
      authBtnList: [],
      userId: '',
    }
  }),
  getters: {
  },
  actions: {
    async setUserInfos() {
      // 存储用户信息到浏览器缓存
      if (Session.get('userInfo')) {
        this.userInfos = Session.get('userInfo');
      } else {
        const userInfos: any = await this.getApiUserInfo();
        this.userInfos = userInfos;
      }
    },
    async getApiUserInfo() {
      return new Promise((resolve) => {
        setTimeout(() => {
          // 模拟数据，请求接口时，记得删除多余代码及对应依赖的引入
          const userName = Local.get('userName');
          // 模拟数据
          let defaultRoles: Array<string> = [];
          let defaultAuthBtnList: Array<string> = [];
          // admin 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
          let adminRoles: Array<string> = ['admin'];
          // admin 按钮权限标识
          let adminAuthBtnList: Array<string> = ['btn.add', 'btn.del', 'btn.edit', 'btn.link'];
          // test 页面权限标识，对应路由 meta.roles，用于控制路由的显示/隐藏
          let testRoles: Array<string> = ['common'];
          // test 按钮权限标识
          let testAuthBtnList: Array<string> = ['btn.add', 'btn.link'];
          // 不同用户模拟不同的用户权限
          if (userName === 'admin') {
            defaultRoles = adminRoles;
            defaultAuthBtnList = adminAuthBtnList;
          } else {
            defaultRoles = testRoles;
            defaultAuthBtnList = testAuthBtnList;
          }
          // 用户信息模拟数据
          const userInfos = {
            userName: userName,
            photo: 'https://img2.baidu.com/it/u=2370931438,70387529&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
            time: new Date().getTime(),
            roles: defaultRoles,
            authBtnList: defaultAuthBtnList,
          };
          resolve(userInfos);
        }, 3000);
      });
    },
  },
  persist: {
    key: 'user',
    storage: window.localStorage,
  },
})

export default useUserStore;
