// router/router.ts
import { RouteRecordRaw } from "vue-router";

export const staticRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    meta: {
      title: "登录",
      keepAlive: false
    },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/",
    name: "homePage",
    redirect: '/public',
    meta: {
      title: "首页",
      keepAlive: false
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/public",
        name: "publicPage",
        meta: {
          title: "公共空间",
          keepAlive: false,
          idx: '0'
        },
        component: () => import("@/views/public/index.vue"),
        alias: '/public'
      },
      {
        path: "/dept/:folderId",
        name: "deptPage",
        meta: {
          title: "部门",
          keepAlive: false,
          idx: '4'
        },
        component: () => import("@/views/home/index.vue"),
        alias: '/dept'
      },
      {
        path: "/my-files/:folderId",
        name: "myFilesPage",
        meta: {
          title: "个人空间",
          keepAlive: false,
          idx: '1'
        },
        component: () => import("@/views/myFiles/index.vue"),
        alias: '/myFiles'
      },
      {
        path: '/share',
        name: 'sharePage',
        meta: {
          title: '分享',
          keepAlive: false,
          idx: '2'
        },
        component: () => import('@/views/sharePage/index.vue')
      },
      {
        path: '/recycle',
        name: 'recyclePage',
        meta: {
          title: '回收站',
          keepAlive: false,
          idx: '3'
        },
        component: () => import('@/views/recycle/index.vue')
      },
    ],
  },
  {
    path: "/extractfile/:encodedId", //NDc=
    meta: {
      title: "提取文件",
      keepAlive: false
    },
    component: () => import("@/views/ExtractFile/ExtractFile.vue")
  },
  {
    path: "/sharefile", //NDc=
    name: "shareFile",
    meta: {
      title: "被分享文件",
      keepAlive: false
    },
    component: () => import("@/views/shareFile/shareFile.vue"),
    children: [
      {
        path: '/sharefile/folder/:folderId',
        name: 'shareChildPage',
        meta: {
          title: "被分享文件",
          keepAlive: false
        },
        component: () => import('@/views/shareFile/shareFile.vue')
      }
    ]
  },
  {
    path: "/setting",
    redirect: "/setting/user",
    meta: {
      title: "基本设置",
      keepAlive: false,
    },
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/setting/user",
        component: () => import("@/views/setting/user.vue"),
        meta: { title: "个人信息", keepAlive: false, showTab: true },
      },
      {
        path: "/setting/roles",
        component: () => import("@/views/setting/rolesManage.vue"),
        meta: { title: "角色管理", keepAlive: false, showTab: true },
      },
    ],
  },
];
