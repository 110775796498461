/* eslint-disable no-undef */
import streamSaver from 'streamsaver';
import ZIP from '@/utils/zip-stream';
import { ElNotification, dayjs } from 'element-plus';

import { createClient } from 'minio-vite-js';

const minioClient = createClient({
  endPoint: "minio.meiletongnian.cn",
  accessKey: "RZixKCzghtMgDnaF",
  secretKey: "RGTCLUr7jzOOBvuhbDFte1JV2B7TgSah",
});

streamSaver.mitm = 'https://cdn.lelepen.com/app/mitm.html?version=2.0.0';

// 下载文件
const getFilePromise = (el: any) => {
  const { localPath, serverPath } = el;
  const objectName = serverPath.split('cloud-disk/')[1];
  return new Promise((resolve) => {
    minioClient.presignedGetObject(
      'cloud-disk',
      objectName,
      // eslint-disable-next-line func-names, no-undef
      async function (err: any, presignedUrl: RequestInfo | URL) {
        fetch(presignedUrl)
          .then((resp) => {
            if (resp.status === 200) {
              return () => resp.body;
            }
            return null;
          })
          .then((stream) => {
            resolve({ name: localPath, stream });
          });
      }
    );
  });
};

// eslint-disable-next-line func-names
export default function () {
  // 下载文件夹，客户端进行打包成zip
  const downloadZip = async (files = []) => {
    console.log(`同步下载打包开始时间：${new Date()}`);
    const date = dayjs().format('YYYYMMDDHHmmss');

    // 创建压缩文件输出流
    const zipFileOutputStream = streamSaver.createWriteStream(
      `download_${date}.zip`
    );
    const startFiles: any[] = [];
    const pullFiles: any[] = [];

    // 创建下载文件流
    // eslint-disable-next-line array-callback-return
    files.map((el: any) => {
      if (el.isDir) {
        startFiles.push({
          name: el.localPath,
          directory: true,
        });
      } else {
        pullFiles.push(getFilePromise(el));
      }
    });
    // const fileIterator = pullFiles.values();
    const readableZipStream = ZIP({
      start(ctrl: any) {
        startFiles.forEach((file) => ctrl.enqueue(file));
      },
      async pull(ctrl: any) {
        // const fileInfo = fileIterator.next();
        // if (fileInfo.done) {
        //   // 迭代终止
        //   ctrl.close();
        // } else {
        //   let { name, stream } = await getFilePromise(fileInfo.value);
        //   ctrl.enqueue({ name, stream });
        //   ctrl.close();
        //   // const { localPath, serverPath } = fileInfo.value;
        //   // let objectName = serverPath.split('cloud-disk/')[1];
        //   // await minioClient.presignedGetObject('cloud-disk', objectName, async function (err, presignedUrl) {
        //   //   const res = await fetch(presignedUrl);
        //   //   ctrl.enqueue({
        //   //     name: localPath,
        //   //     stream: () => res.body,
        //   //   });
        //   // });
        // }

        await Promise.all(pullFiles).then((res) => {
          const nameMapList: any[] = [];
          res.forEach((item) => {
            let { name } = item;
            const { stream } = item;
            const nameList = nameMapList.map((nameMap) => nameMap.name);
            // 校验文件名称是否存在，如果存在则修改文件名称
            // eslint-disable-next-line eqeqeq
            if (nameList.indexOf(name) == -1) {
              nameMapList.push({ name, cnt: 0 });
            } else {
              // eslint-disable-next-line no-shadow
              const nameItem = nameMapList.find((item) => item.name === name);
              nameItem.cnt += 1;
              const fileName = name.substring(0, name.lastIndexOf('.'));
              const prefix = name.substr(name.lastIndexOf('.'));
              name = `${fileName}(${nameItem.cnt})${prefix}`;
            }
            if (item.stream) {
              const file = { name, stream };
              ctrl.enqueue(file);
            }
          });
        });
        // // if (done adding all files)
        ctrl.close();
        // ElNotification.closeAll();
        // ElNotification({
        //   type: 'success',
        //   message: '下载文件成功',
        // });
      },
    });
    if (window.WritableStream && readableZipStream.pipeTo) {
      ElNotification.closeAll();
      ElNotification({
        title: "下载",
        message: "正在下载...",
        duration: 0,
      });
      // 开始下载
      readableZipStream
        .pipeTo(zipFileOutputStream)
        .then(() => {
          console.log(`同步下载打包结束时间：${new Date()}`);
          ElNotification.closeAll();
          ElNotification({
            title: "下载",
            type: "success",
            message: "下载文件成功",
          });
        })
        .catch(() => {
          console.log('网络不佳,下载失败');
        });
    }
  };

  // 下载单文件
  const downloadSingleFile = async (serverPath = '', localName = '') => {
    const objectName = serverPath.split('cloud-disk/')[1];
    minioClient.presignedGetObject(
      'cloud-disk',
      objectName,
      (err: any, presignedUrl: RequestInfo | URL) => {
        const fileStream = streamSaver.createWriteStream(localName);
        // eslint-disable-next-line consistent-return
        fetch(presignedUrl).then((res) => {
          const readableStream = res.body;

          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream
              .pipeTo(fileStream)
              .then(() => console.log('done writing'));
          }
          window.writer = fileStream.getWriter();

          const reader = res.body.getReader();
          const pump = () =>
            reader
              .read()
              // eslint-disable-next-line no-shadow
              .then((res) =>
                res.done ? writer.close() : writer.write(res.value).then(pump)
              );
          pump();
        });
      }
    );
  };

  return {
    downloadZip,
    downloadSingleFile,
  };
}
