import useDownload from "@/hooks/useDownload";
import request from "@/utils/request";
import qs from "qs";
import { ElNotification, ElMessage } from "element-plus";

/**
 * 文件api接口集合
 */
export function useFileApi() {
  return {
    // 获取文件夹列表
    getFoldersList: (params: object = {}) => {
      return request.get("/netdisk-folders/list", params);
    },

    // 获取文件列表
    getFilesList: (params: object = {}) => {
      return request.get("/netdisk-files/list", params);
    },

    // 获取文件分享链接
    getShareCode: (params: object = {}) => {
      return request.post("/netdisk-sharelink/save", params);
    },

    // 分享链接获取链接详情
    getShareDetail: (params: object = {}) => {
      return request.get("/netdisk-sharelink/detail", params);
    },

    // 根据提取码提取文件
    getShareFileByCode: (params: object = {}) => {
      return request.get("/netdisk-sharelink/findShareFile", params);
    },

    // 删除文件 逻辑删除
    deleteFiles: (params: object = {}) => {
      return request.post("/netdisk-files/remove", params);
    },

    // 删除分享链接 逻辑删除
    deleteShareFileCode: (params: object = {}) => {
      return request.post("/netdisk-sharelink/remove", params);
    },

    // 获取下载列表
    getDownloadList: (params: object = {}) => {
      const { downloadZip } = useDownload();
      request
        .get("/netdisk-files/downloadFileList", params)
        .then((res: any) => {
          if (res?.data?.data.length) {
            let fileSize = 0;

            res?.data?.data.map((item: any) => {
              fileSize += item.fileLength || 0;
            })

            let index = Math.floor(Math.log(fileSize) / Math.log(1024));
            let size = fileSize / Math.pow(1024, index);

            if (size >= 2 && index >= 3) {
              return ElMessage({
                message: "文件总大小超过2G，请分开下载！",
                type: "warning",
                duration: 3000
              })
            } else {
              ElNotification({
                message: "下载准备中...",
                duration: 0,
              });
              downloadZip(res?.data?.data);
            }
          }
        });
    },

    downloadSingleFile: (serverPath: string, localName: string) => {
      const { downloadSingleFile } = useDownload();
      downloadSingleFile(serverPath, localName);
    },

    // 新建文件夹
    cerateNewFolder(params: object = {}) {
      return request.post("/netdisk-folders/createFolder", params);
    },

    // 获取预览服务器地址
    getPreviewHost() {
      return request.get("/netdisk-common/getPreviewHost");
    },

    // 获取面包屑
    getPath(params: object = {}) {
      return request.get("/netdisk-folders/parentFolder", params);
    },

    // 修改文件夹
    updateFolder(params: object = {}) {
      return request.post("/netdisk-folders/update", params);
    },

    // 修改文件
    updateFile(params: object = {}) {
      return request.post("/netdisk-files/updateFileName", params);
    },

    // 获取所有被分享的文件
    getShareFiles(params: object = {}) {
      return request.get("/netdisk-sharelink/page", params);
    },

    // 获取所有被删除的文件
    getDeleteFiles(params: any) {
      return request.get("/netdisk-files/trashCan", params);
    },

    // 回收站还原
    reBackFiles(params: []) {
      return request.post("/netdisk-files/restore", params);
    },

    // 彻底删除文件
    completelyDelete(params: [] | {}) {
      return request.post("/netdisk-operation/absolutelyDelete", params);
    },

    // 我的文件
    showMyFiles() {
      return request.get("/netdisk-files/myFiles");
    },

    // 移动文件
    moveFiles(params: {}) {
      return request.post("/netdisk-files/move", params);
    },

    // 获取标签
    getTagList(params: {}) {
      // return request.get('/course/product/tag/list', params, { baseURL: '/vapi'})
      return request.get("/netdisk-common/tagList", params);
    },

    // 搜索
    searchFiles(params: {}) {
      return request.post("/netdisk-files/search", params);
    },

    // 获取文件预览uuid参数
    getDecryptUUID() {
      return request.post("/netdisk-common/preview/getDecryptUUID");
    },

    // 共享空间
    getCompanyFiles(params: object = {}) {
      return request.get("/netdisk-files/companySharedFile", params);
    },

    // 获取初始展示可移动部门
    getMoveDepartment(params?: number | undefined) {
      if (params) {
        return request.get("/netdisk-folders/move/list?pid=" + params);
      } else {
        return request.get("/netdisk-folders/move/list");
      }
    },
    // 设置/取消 共享空间
    shareCompanyFiles(params: object = {}) {
      return request.get("/netdisk-files/markShare", params);
    },

    // 修改标签
    updateFileTag(params: object = {}) {
      return request.post("/netdisk-files/updateFileTag", params);
    },

    /**
     * 根据文件获取未上传完的任务
     */
    taskInfo(params: object = {}) {
      return request.get(`/netdisk-operation/taskInfo`, params);
    },

    /**
     * 初始化一个分片上传任务
     */
    initTask(params: object = {}) {
      return request.post("/netdisk-operation/initTask", params);
    },

    /**
     * 获取预签名分片上传地址
     */
    preSignUrl(params: object = {}) {
      return request.get(`/netdisk-operation/preSignUploadUrl`, params);
    },

    /**
     * 合并分片
     */
    merge(params: object = {}) {
      return request.post(`/netdisk-operation/merge`, params);
    },

    /**
     * 下载文件
     * @param serverPath
     * @param type  文件类型 0：文件 1： 文件夹
     */
    download(params: object = {}) {
      return request.get(`/netdisk-operation/download`, params);
    },

    /**
     * 生成缩略图
     * @param fileId  文件id
     */
    getThumbnail(fileId: number) {
      return request.post(`/netdisk-operation/genThumbnail/${fileId}`);
    },

    /**
     * 用户列表
     * @param fileId  文件id
     */
    getUserList(params: object = {}) {
      return request.get(`/blade-user/selectList`, params);
    },
  };
}

// 下载文件
function downloadFile(params: object = {}) {
  ElNotification({
    message: "下载准备中...",
    duration: 0,
  });

  request
    .get("/netdisk-files/download", params, {
      responseType: "blob",
      timeout: 3000000,
    })
    .then((res: any) => {
      const { data, headers } = res;
      const filename = headers["content-disposition"];

      const url = window.URL.createObjectURL(
        new Blob([data], { type: headers["content-type"] })
      );
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", decodeURI(filename.split("filename=")[1]));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象

      console.log(res);
      ElNotification.closeAll();
      ElNotification({
        type: "success",
        message: "下载文件成功",
      });
    })
    .catch((err: any) => {
      console.log(err);
      ElNotification.closeAll();
    });
}
