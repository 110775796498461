import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

import router from '@/router'
import store from '@/store'
import $http from '@/utils/request.js'

import uploader from '@/components/vueSimpleUploader'

import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/styles/main.scss'
import './assets/styles/tailwind.css'
import './assets/styles/util.scss'

const app = createApp(App)

app.config.globalProperties.$http = $http

app.use(ElementPlus, {
  locale: zhCn,
})
app.use(store)
app.use(router)
app.use(uploader)
app.use(ContextMenu)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}