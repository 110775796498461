import Bus from "@/utils/bus";
import { useFileStore } from '@/store'
import { useFileApi } from "@/api/file";
import { Local, Session } from "@/utils/storage";

const fileApi = useFileApi();

export default function () {
  // 打开文件选择框
  const fileUpload = () => {
    const fileStore = useFileStore()
    Bus.emit("openFileUploader", {
      params: {
        folderId: fileStore.folderId
      },
      options: {
        target: "/api/netdisk-files/chunkUpload",
        headers: {
          'blade-auth': 'bearer ' + Local.get('token'),
          'Authorization': `Basic ${window.btoa("saber:saber_secret")}`,
          'Tenant-Id': '000000',
        },
      },
    });
  };

  // 打开文件夹选择框
  const folderUpload = () => {
    const fileStore = useFileStore()
    Bus.emit("openFolderUploader", {
      params: {
        folderId: fileStore.folderId
      },
      options: {
        target: "/api/netdisk-files/chunkUpload",
        headers: {
          'blade-auth': 'bearer ' + Local.get('token'),
          'Authorization': `Basic ${window.btoa("saber:saber_secret")}`,
          'Tenant-Id': '000000',
        },
      },
    });
  };

  // 文件选择后的回调
  const fileAdded = () => {
    Bus.on("fileAdded", () => {
      // console.log("文件已选择");
    });
  }

  // 文件上传成功的回调
  const fileSuccess = () => {
    Bus.on("fileSuccess", () => {
      console.log("文件上传成功");
    });
  }

  // 解除文件选择后的回调
  const offFileAdded = () => {
    Bus.off("fileAdded");
  }

  // 解除文件上传成功的回调
  const offFileSuccess = () => {
    Bus.off("fileSuccess");
  }

  // 获取menu
  const getFolders = (cb: any = null) => {
    const fileStore = useFileStore()
    fileApi.getFoldersList().then((data: any) => {

      let deptList: any[] = [];
      data.map((item: any) => {
        let _path = '', _icon = '';
        if (item.shared == 1) {
          _path = `/public`
          _icon = "cloud"
          fileStore.pubFolderId = data[0].id
          fileStore.pubPidRoot = data[0].id
        } else if (item.myOwn == 1) {
          _path = "/my-files"
          _icon = "person"
        } else {
          _path = "/dept"
          _icon = "home"
        }

        deptList.push({
          name: item.localName,
          path: _path,
          hidden: false,
          pidRoot: item.id,
          meta: {
            idx: item.shared == 1 ? 'c-1' : String(item.id),
            title: item.localName,
            icon: _icon,
            permission: item.permission
          },
        });
      });

      Local.set("dept", deptList);
      // signInSuccess()
      cb && cb()
    })
  }

  return {
    fileUpload,
    folderUpload,
    fileAdded,
    fileSuccess,
    offFileAdded,
    offFileSuccess,
    getFolders
  }
}