import axios from 'axios'
import { ElMessage } from 'element-plus'
import { Local } from '@/utils/storage'

const config = {
  // baseURL: "https://pan.meiletongnian.cn/api",
  baseURL: "/api",
  timeout: 28800000,
  headers: {
    Authorization: `Basic ${window.btoa("saber:saber_secret")}`,
    "Tenant-Id": "000000",
  },
};

const instance = axios.create(config)

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (Local.get('token')) {
    config.headers['blade-auth'] = 'bearer ' + Local.get('token')
    // config.headers['Authorization'] = `Basic ${window.btoa("saber:saber_secret")}`
    // config.headers['Tenant-Id'] = '000000'
  }
  // if(config.url.includes('/netdisk-sharelink/remove') && config.method == 'post') {
  //   config.headers['Content-Type'] = 'multipart/form-data'
  // }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  if (response.status === 200 && response.config.url.includes('/download')) {
    return response
  } else if (response.status === 200 && response.config.url.includes('/netdisk-operation')) {
    return response.data
  } else if (response.data.code === 200 || response.data.code === 2000) {
    return response.data.data
  } else {
    console.error(response)
    errHandler(response?.data?.msg)
    return Promise.reject(response.data.msg)
  }
}, error => {
  console.error(error)
  errHandler(error?.response?.data?.msg)
  return Promise.reject(error.response.data.msg)
})

const errHandler = errMsg => {
  ElMessage.error(errMsg)
  if (Local.get('token') && errMsg === '请求未授权') {
    Local.remove('token')
    location.reload()
  }
}

const $http = {
  get(url, params = {}, ops = {}) {
    ops = {
      params,
      ...ops,
    };
    return instance.get(url, ops);
  },
  post(url, params = {}, ops = {}) {
    ops = {
      ...ops,
    };
    return instance.post(url, params, ops);
  },
};

export default $http