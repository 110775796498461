import { defineStore } from 'pinia'
import { ThemeConfigState } from './types';

const useAppStore = defineStore("app", {
  state: (): ThemeConfigState => ({
    /**
     * 界面设置
     */
    // 是否开启菜单水平折叠效果
    isCollapse: false,
    isUploading: false,
    showPanel: false,
    collapsePanel: false,
  }),
});

export default useAppStore;