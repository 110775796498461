import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import useAppStore from './modules/app';
import useUserStore from './modules/user';
import useFileStore from './modules/file';
// import useTagsStore from './modules/tags';

const pinia = createPinia();
pinia.use(piniaPluginPersistedState)

export { useAppStore, useUserStore, useFileStore };
export default pinia;
